<template>
  <v-card>
    <v-card-text class="py-4 text-center import-section">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div
        class="import-wrapper p-0 p-md-16"
        @drop.prevent="onDrop"
        @dragover.prevent
        @dragenter.prevent
        v-if="noRecords"
      >
        <p>
          <v-icon class="primary--text">mdi-tag-multiple</v-icon>
          <strong> {{ areReleases ? 'Release' : 'Tag' }} Batch Import</strong>
        </p>
        <p class="text-h5 font-weight-thin">
          Upload the file by dragging and dropping into the dashed area or click
          the below button
        </p>
        <v-btn class="primary" @click="chooseFiles">Upload file</v-btn>
        <input
          class="hidden"
          id="fileUpload"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="onFileInputChange"
        />

        <div class="font-weight-thin">
          <v-banner>
            <v-icon slot="icon" color="primary" size="36">
              mdi-alert-circle
            </v-icon>
            Remember to download the latest
            <v-btn @click="exportTemplate" text color="primary">
              Template File
            </v-btn>
          </v-banner>
          <span>
            Microsoft Excel is recommended for the best experience in viewing
            and editing the file
          </span>
          <v-spacer></v-spacer>
        </div>
      </div>
      <div v-else>
        <v-toolbar flat class="primary mb-2" dark>
          <v-toolbar-title>
            <v-icon left> mdi-tag-multiple </v-icon>
            Batch Import
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn-toggle
            dense
            mandatory
            borderless
            class="ml-2"
            color="white"
            group
            v-model="toggleRecords"
          >
            <v-btn value="total" :disabled="records.length === 0">
              <v-icon left>mdi-file-document-multiple</v-icon>
              Total: {{ records.length }}
            </v-btn>
            <v-btn
              value="valid"
              :disabled="records.length - invalidRecords === 0"
            >
              <v-icon left>mdi-file-document-check</v-icon>
              Valid: {{ records.length - invalidRecords }}
            </v-btn>
            <v-btn value="invalid" :disabled="invalidRecords === 0">
              <v-icon left>mdi-file-document-alert</v-icon>
              Invalid: {{ invalidRecords }}
            </v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
          <v-btn color="white" outlined class="ml-2" @click="clearData">
            Clear
          </v-btn>
          <v-btn
            color="white"
            depressed
            class="ml-2 primary--text"
            @click="submitRecords"
          >
            Submit
          </v-btn>
        </v-toolbar>
        <div :class="{ 'list-container': true, hidden: overlay }">
          <v-expansion-panels v-model="panel" :readonly="readonly" flat>
            <v-expansion-panel
              v-for="(item, index) of records"
              :key="index"
              ref="recordPanel"
              :class="{ 'd-none': hideRecord(index) }"
            >
              <v-expansion-panel-header
                expand-icon=""
                :class="{ highlight: !readonly }"
              >
                <template v-slot:default="{ open }">
                  <div v-if="!open">
                    <v-tooltip right :key="item.number">
                      <template v-slot:activator="{ on }">
                        <span v-on="readonly ? {} : on">
                          <record-preview
                            :record="item"
                            :recordType="recordType"
                          />
                        </span>
                      </template>
                      <span>Click here to edit</span>
                    </v-tooltip>
                  </div>
                  <v-row no-gutters class="align-center" v-else>
                    <v-chip
                      class="white--text ml-2"
                      color="primary"
                      label
                      outlined
                    >
                      <v-icon left>mdi-tag-text</v-icon>
                      {{ item.number || `ROW ${index + 3}` }}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <apply-all
                      v-model="populateParams"
                      :isRelease="areReleases"
                    />
                    <v-spacer></v-spacer>
                  </v-row>
                </template>
                <template v-slot:actions="{ open }">
                  <div v-if="open">
                    <v-btn color="primary" outlined @click="closePanel">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      depressed
                      class="ml-2"
                      @click="(event) => validateRecord(event, index)"
                    >
                      Validate
                    </v-btn>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="card-container">
                <template v-slot:default="{ open }">
                  <release-form
                    v-if="areReleases"
                    ref="tagsForm"
                    :record="open ? item : null"
                    @newUser="populateUser"
                  />
                  <tag-form
                    ref="tagsForm"
                    :record="open ? item : null"
                    @newUser="populateUser"
                    v-else
                  />
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import {
  notifyConfirmation,
  notifyError,
  notifyMessage
} from '../../helpers/notifications';
import {
  fixTimezone,
  formatDate,
  getFile,
  selectSheetRange
} from '../../helpers/format';
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel,
  FishReleaseModel
} from '../users/viewmodels';
import * as ExcelJS from 'exceljs';
import TagForm from '../users/forms/sections/TagForm.vue';
import ReleaseForm from '../users/forms/sections/ReleaseForm.vue';
import RecordPreview from './RecordPreview.vue';
import ApplyAll from './ApplyAll.vue';
import { LENGTH_UNITS, WEIGHT_UNITS } from '../../helpers/constants';
export default {
  name: 'batch-import',
  components: {
    'tag-form': TagForm,
    'release-form': ReleaseForm,
    'record-preview': RecordPreview,
    'apply-all': ApplyAll
  },
  data: () => ({
    records: [],
    toggleRecords: 'total',
    overlay: false,
    userEmails: [],
    panel: null,
    readonly: false,
    populateParams: null,
    recordType: 'tags',
    species: [],
    baits: [],
    gears: []
  }),
  async mounted() {
    this.species = await this.getSystemValues('species');
    this.baits = await this.getSystemValues('baits');
    this.gears = await this.getSystemValues('gears');
  },
  computed: {
    noRecords() {
      return !this.records.length;
    },
    invalidRecords() {
      return this.records.filter(({ ready }) => !ready)?.length || 0;
    },
    areReleases() {
      return this.recordType === 'releases';
    }
  },
  watch: {
    panel: {
      handler(panelIndex) {
        if (panelIndex === null) {
          this.readonly = false;
          return;
        }

        if (this.records?.length && this.records[panelIndex]) {
          this.records[panelIndex].ready = false;
          if (this.$refs.recordPanel) {
            setTimeout(() => {
              this.$refs.recordPanel[panelIndex].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              });
            }, 500);
          }
        }
      }
    },
    '$route.query': {
      handler(query) {
        const { recordType: recordTypeParam } = query || {};
        const lastBatchVisited = localStorage.getItem('lastBatchVisited');
        const recordType =
          recordTypeParam || lastBatchVisited || this.recordType || 'tags';

        const savedBatchUpload = localStorage.getItem(
          `${recordType}BatchUpload`
        );

        const { recordType: savedRecordType, records } = JSON.parse(
          savedBatchUpload || '{}'
        );

        this.recordType = recordType;
        this.records = records || [];

        if (!recordTypeParam) {
          return;
        }

        this.$router.replace({ query: null });

        const batchUpload = {
          recordType,
          records: recordType === savedRecordType ? records || [] : []
        };

        localStorage.setItem(
          `${recordType}BatchUpload`,
          JSON.stringify(batchUpload)
        );

        localStorage.setItem('lastBatchVisited', recordType);
      },
      immediate: true
    },
    records: {
      handler(records) {
        const batchUpload = {
          recordType: this.recordType,
          records
        };

        localStorage.setItem(
          `${this.recordType}BatchUpload`,
          JSON.stringify(batchUpload)
        );

        if (!records?.length) {
          this.panel = null;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('query', ['getTemplate', 'getUsers', 'getItems']),
    ...mapActions('entry', ['checkEvent', 'addTag', 'addRelease']),
    ...mapActions('users', ['getExistentUsers']),

    onFileInputChange(event) {
      const { target } = event || {};
      const [file] = target?.files || [];

      this.handleFile(file);
    },

    onDrop(event) {
      const { dataTransfer } = event || {};
      const [file] = dataTransfer?.files || [];

      this.handleFile(file);
    },

    handleFile(file) {
      if (!file) {
        return;
      }

      const [extension] = file.name?.split('.').reverse() || [];

      if (!extension || extension.toLowerCase() !== 'xlsx') {
        notifyError('Unsupported file type, only Excel files allowed');
        return;
      }

      this.overlay = true;

      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        try {
          const { result } = readerEvent.target;

          if (!result) {
            throw new Error('Invalid file');
          }

          await this.getRecords(result);
        } catch (error) {
          console.error(error);
          notifyError(
            'An error ocurred parsing your submission, please download & use the latest template'
          );
        }

        this.overlay = false;
      };
      reader.readAsArrayBuffer(file);
    },

    chooseFiles() {
      document.getElementById('fileUpload').click();
    },

    async exportTemplate() {
      this.overlay = true;
      try {
        const response = await this.getTemplate({
          recordType: this.areReleases ? 'release' : 'tag'
        });
        const { data } = response || {};
        if (!data) {
          notifyError('Unable to build the tag batch template');
          this.overlay = false;
          return;
        }
        getFile(
          data,
          'xlsx',
          `${this.areReleases ? 'Release' : 'Tag'} Template`
        );
      } catch (error) {
        notifyError('Unable to build the batch template');
      }
      this.overlay = false;
    },

    async getRecords(buffer) {
      try {
        const book = new ExcelJS.Workbook();
        const workbook = await book.xlsx.load(buffer);

        const [worksheet] = workbook.worksheets;

        const rangedData = selectSheetRange(
          worksheet,
          this.areReleases ? 'A:AE' : 'A:AF'
        );

        const [row] = rangedData || [];
        if (!row?.length) {
          throw new Error('Invalid template');
        }

        const [firstColumn] = row;
        const [notesColumn] = row.reverse();

        if (!this.areReleases) {
          if (row.length !== 32) {
            throw new Error('Invalid column count for tags');
          }

          if (firstColumn !== 'TAG NUMBER' || notesColumn !== 'NOTES') {
            throw new Error(
              'Invalid template, first and last columns mismatch for tags'
            );
          }
        }

        if (this.areReleases) {
          if (row.length !== 31) {
            throw new Error('Invalid column count for releases');
          }

          if (firstColumn !== 'MM/DD/YYYY' || notesColumn !== 'NOTES') {
            throw new Error(
              'Invalid template, first and last columns mismatch for releases'
            );
          }
        }

        const records = [];
        this.userEmails = [];
        for (const [rowIndex, row] of rangedData.entries()) {
          if (rowIndex < 2) {
            continue;
          }

          if (this.areReleases) {
            const record = this.parseReleaseRecords(row);
            records.push(record);
            continue;
          }

          const record = await this.parseTagRecords(row);
          records.push(record);
        }

        this.records = records;
      } catch (error) {
        console.error(error);
        notifyError(
          'An error ocurred parsing your submission, please download & use the latest template'
        );
        return;
      }

      const response = await this.getExistentUsers({ emails: this.userEmails });
      const users = response?.data?.docs || [];

      const fillUsers = (crewPosition, record) => {
        const user = users.find(
          ({ email }) => email === record[crewPosition].id
        );

        if (user) {
          record[crewPosition] = user;
        }
      };

      for (const index of this.records.keys()) {
        ['angler', 'captain', 'firstMate', 'secondMate'].forEach(
          (crewPosition) => {
            fillUsers(crewPosition, this.records[index]);
          }
        );

        const recordErrors = await new Promise((resolve) => {
          this.panel = index;

          setTimeout(() => {
            this.$nextTick(async () => {
              let errorBag = [];
              if (this.$refs.tagsForm && this.$refs.tagsForm[index]) {
                const submitResponse = await this.$refs.tagsForm[
                  index
                ].submit();
                const { valid, data, errors } = submitResponse || {};

                if (!valid) {
                  errorBag.push(...errors);
                }

                const response = await this.checkEvent({
                  payload: data,
                  event: this.recordType
                });

                const { docs } = response?.data || {};

                if (docs) {
                  errorBag.push('duplicated');
                }
              }

              this.panel = null;
              resolve(errorBag);
            });
          }, 750);
        });

        this.records[index].errors = recordErrors;

        if (!recordErrors?.length) {
          this.records[index].ready = true;
        }
      }

      if (this.noRecords) {
        notifyMessage('No valid records found');
        return;
      }
    },

    retrieveUserEmail(cellValue) {
      if (!cellValue) {
        return null;
      }

      if (typeof cellValue === 'object') {
        const { text } = cellValue || {};
        if (!text) {
          return null;
        }

        this.userEmails.push(text);
        return text;
      }

      if (typeof cellValue !== 'string') {
        return null;
      }

      this.userEmails.push(cellValue);
      return cellValue;
    },

    async closePanel(event) {
      event?.stopPropagation();
      event?.preventDefault();

      this.panel = null;
      this.populateParams = null;
    },

    async validateRecord(event, index) {
      event?.stopPropagation();
      event?.preventDefault();

      if (this.$refs.tagsForm && this.$refs.tagsForm[index]) {
        const submitResponse = await this.$refs.tagsForm[index].submit();
        const { valid, data } = submitResponse || {};

        if (!valid) {
          notifyMessage('Record is invalid, check the fields');
          return;
        }

        const response = await this.checkEvent({
          payload: data,
          event: this.recordType
        });

        const { docs } = response?.data || {};

        if (docs) {
          notifyMessage('Tag number already exists');
          return;
        }

        const records = [...this.records];
        const params = this.populateParams?.filter(({ value }) => value) || [];
        for (const param of params) {
          const { name } = param || {};

          for (const recordIndex of records.keys()) {
            if (recordIndex === index) {
              continue;
            }

            if (name === 'location') {
              records[recordIndex].coordinates = data.coordinates || {};
              records[recordIndex].ocean = data.capture.ocean;
              continue;
            }

            if (name === 'boat') {
              records[recordIndex].boat = data.boat;
              records[recordIndex].boatName = data.boat.name;
              continue;
            }

            if (name === 'angler') {
              records[recordIndex].angler = data.angler;
              continue;
            }

            if (name === 'captain') {
              records[recordIndex].captain = data.captain;
              continue;
            }

            if (name === 'firstMate') {
              records[recordIndex].firstMate = data.firstMate;
              continue;
            }

            if (name === 'secondMate') {
              records[recordIndex].secondMate = data.secondMate;
              continue;
            }

            if (name === 'tournament') {
              records[recordIndex].capture.tournament = data.capture.tournament;
              continue;
            }

            if (name === 'hook') {
              records[recordIndex].capture.hook = data.capture.hook;
              continue;
            }

            if (name === 'bait') {
              records[recordIndex].capture.bait = data.capture.bait;
              continue;
            }

            if (name === 'gear') {
              records[recordIndex].capture.gear = data.capture.gear;
              continue;
            }

            if (name === 'species') {
              if (this.areReleases) {
                records[recordIndex].capture.fish = data.capture.fish;
                continue;
              }

              records[recordIndex].capture.fish.specie =
                data.capture.fish.specie;
              continue;
            }
          }
        }

        records[index] = data;
        records[index].ready = true;
        records[index].errors = [];
        this.records = [...records];
        this.panel = null;
        this.populateParams = null;
        return;
      }
    },

    async submitRecords() {
      if (this.invalidRecords) {
        this.toggleRecords = 'invalid';
        notifyMessage('Some records are invalid, please check it');
        return;
      }

      this.overlay = true;

      try {
        const operation = this.areReleases ? 'addRelease' : 'addTag';
        const response = await this[operation](this.records);
        const { success, message } = response?.data || {};

        if (!success) {
          notifyError(message);
          this.overlay = false;
          return;
        }

        notifyConfirmation('Records were successfully registered', 'All done!');
        this.records = [];
      } catch (error) {
        notifyError("Records weren't registered", 'Unable to save');
      }

      this.overlay = false;
    },

    clearData() {
      notifyConfirmation(
        'Are you sure to proceed, this will clear your submission',
        'Clear Data',
        () => (this.records = []),
        () => {},
        'Proceed'
      );
    },

    populateUser(user) {
      const { email } = user || {};

      for (const record of this.records) {
        for (const crewPosition of [
          'angler',
          'captain',
          'firstMate',
          'secondMate'
        ]) {
          if (record[crewPosition].id === email) {
            record[crewPosition] = user;
          }
        }
      }
    },

    hideRecord(index) {
      if (this.toggleRecords === 'valid') {
        return !this.records[index]?.ready;
      }

      if (this.toggleRecords === 'invalid') {
        return this.records[index]?.ready;
      }

      return false;
    },

    async parseTagRecords(row = []) {
      const record = {
        ...new CrewViewModel(),
        ...new CaptureViewModel(),
        ...new CoordinatesViewModel(),
        ...{ boatName: null },
        ready: false,
        errors: []
      };

      for (const [columnIndex, cellValue] of row.entries()) {
        switch (columnIndex) {
          case 0: {
            const haveBF = String(cellValue).startsWith('BF');
            record.number = cellValue;

            if (cellValue && !haveBF) {
              record.number = 'BF' + cellValue;
            }
            break;
          }

          case 1: {
            if (!cellValue) {
              break;
            }

            const ocean = ['PACIFIC', 'ATLANTIC', 'INDIAN'].find(
              (value) => value === cellValue.toUpperCase()
            );

            if (ocean) {
              record.capture.ocean = ocean;
            }
            break;
          }

          case 2: {
            if (!cellValue) {
              break;
            }

            const species = this.species.find(
              ({ name }) => name.toLowerCase() === cellValue.toLowerCase()
            );

            if (!species) {
              throw new Error('Invalid species');
            }

            record.capture.fish.specie = species;
            break;
          }

          case 3: {
            if (cellValue) {
              const date = fixTimezone(cellValue);
              record.createdDate = formatDate(date);
            }

            record.enteredDate = new Date();
            break;
          }

          case 4:
            record.coordinates.latitude.degrees = cellValue;
            break;

          case 5:
            record.coordinates.latitude.minutes = cellValue;
            break;

          case 6:
            record.coordinates.latitude.hemisphere = cellValue;
            break;

          case 7:
            record.coordinates.longitude.degrees = cellValue;
            break;

          case 8:
            record.coordinates.longitude.minutes = cellValue;
            break;

          case 9:
            record.coordinates.longitude.hemisphere = cellValue;
            break;

          case 10: {
            record.coordinates.description = cellValue;
            record.locality = cellValue;
            break;
          }

          case 11:
            record.capture.fish.length.value = cellValue;
            break;

          case 12: {
            const unit = Object.values(LENGTH_UNITS).find((value) =>
              value.includes(cellValue)
            );
            record.capture.fish.length.unit = unit;
            break;
          }

          case 13:
            record.capture.fish.length.type = cellValue;
            break;

          case 14:
            record.capture.fish.length.determination = cellValue;
            break;

          case 15:
            record.capture.fish.weight.value = cellValue;
            break;

          case 16: {
            const unit = Object.values(WEIGHT_UNITS).find((value) =>
              value.includes(cellValue)
            );
            record.capture.fish.weight.unit = unit;
            break;
          }

          case 17:
            record.capture.fish.weight.type = cellValue;
            break;

          case 18:
            record.capture.fish.weight.determination = cellValue;
            break;

          case 19: {
            if (!cellValue) {
              break;
            }

            const bait = this.baits.find(
              ({ name }) => name.toLowerCase() === cellValue.toLowerCase()
            );

            if (!bait) {
              throw new Error('Invalid bait');
            }

            record.capture.bait.name = bait.name;
            break;
          }

          case 20:
            record.capture.fish.condition.name = cellValue;
            break;

          case 21: {
            const totalMinutes = parseInt(cellValue) || 0;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            record.capture.fish.fight.hours = hours;
            record.capture.fish.fight.minutes = minutes;
            break;
          }

          case 22:
            record.capture.hook.name = cellValue;
            break;

          case 23: {
            if (!cellValue) {
              record.capture.gear.name = 'ROD AND REEL';
              break;
            }

            const gear = this.gears.find(
              ({ name }) => name.toLowerCase() === cellValue.toLowerCase()
            );

            if (!gear) {
              throw new Error('Invalid gear');
            }

            record.capture.gear.name = gear.name;
            break;
          }

          case 24:
            record.capture.tournament.name = cellValue;
            break;

          case 25: {
            record.boat.name = cellValue;
            record.boatName = cellValue;
            break;
          }

          case 26:
            record.sharkEncounter = /yes/i.test(cellValue);
            break;

          case 27: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.angler.id = email;
            }
            break;
          }

          case 28: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.captain.id = email;
            }
            break;
          }

          case 29: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.firstMate.id = email;
            }
            break;
          }

          case 30: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.secondMate.id = email;
            }
            break;
          }

          case 31:
            record.capture.notes = cellValue;
            break;

          default:
            break;
        }
      }

      return record;
    },

    parseReleaseRecords(row = []) {
      const record = {
        ...new CrewViewModel(),
        ...new CaptureViewModel(),
        ...new CoordinatesViewModel(),
        ...{ boatName: null },
        ...{
          capture: {
            ...new CaptureViewModel().capture,
            fish: []
          }
        },
        ready: false,
        errors: []
      };

      const addFish = (name, qty = 1) => {
        if (!name) {
          return;
        }

        const species = this.species.find(
          ({ name: fishName }) => fishName.toLowerCase() === name.toLowerCase()
        );

        if (!species) {
          throw new Error('Invalid species');
        }

        const { _id, id, name: fishName, acronym } = species;
        const fish = new FishReleaseModel(_id || id, acronym, fishName, qty);
        record.capture.fish.push(fish);
      };

      const updateFishQty = (qty = 1) => {
        const quantity = Number(qty);
        const [fish] = record.capture.fish.reverse() || [];

        if (!fish) {
          return;
        }

        fish.qty = quantity;
      };

      for (const [columnIndex, cellValue] of row.entries()) {
        switch (columnIndex) {
          case 0: {
            if (cellValue) {
              const date = fixTimezone(cellValue);
              record.createdDate = formatDate(date);
            }

            record.enteredDate = new Date();
            break;
          }

          case 1: {
            if (!cellValue) {
              break;
            }

            const ocean = ['PACIFIC', 'ATLANTIC', 'INDIAN'].find(
              (value) => value === cellValue.toUpperCase()
            );

            if (ocean) {
              record.capture.ocean = ocean;
            }
            break;
          }

          case 2:
            record.coordinates.latitude.degrees = cellValue;
            break;

          case 3:
            record.coordinates.latitude.minutes = cellValue;
            break;

          case 4:
            record.coordinates.latitude.hemisphere = cellValue;
            break;

          case 5:
            record.coordinates.longitude.degrees = cellValue;
            break;

          case 6:
            record.coordinates.longitude.minutes = cellValue;
            break;

          case 7:
            record.coordinates.longitude.hemisphere = cellValue;
            break;

          case 8: {
            record.coordinates.description = cellValue;
            record.locality = cellValue;
            break;
          }

          case 9:
            addFish(cellValue);
            break;

          case 10:
            updateFishQty(cellValue);
            break;

          case 11:
            addFish(cellValue);
            break;

          case 12:
            updateFishQty(cellValue);
            break;

          case 13:
            addFish(cellValue);
            break;

          case 14:
            updateFishQty(cellValue);
            break;

          case 15:
            addFish(cellValue);
            break;

          case 16:
            updateFishQty(cellValue);
            break;

          case 17:
            addFish(cellValue);
            break;

          case 18:
            updateFishQty(cellValue);
            break;

          case 19: {
            if (!cellValue) {
              break;
            }

            const bait = this.baits.find(
              ({ name }) => name.toLowerCase() === cellValue.toLowerCase()
            );

            if (!bait) {
              throw new Error('Invalid bait');
            }

            record.capture.bait.name = bait.name;
            break;
          }

          case 20:
            record.capture.hook.name = cellValue;
            break;

          case 21: {
            const time = fixTimezone(cellValue);

            if (!time) {
              break;
            }

            time.set('seconds', 0);
            time.add(1, 'minute');
            record.relLines = time.format('HH:mm');
            break;
          }

          case 22: {
            const time = fixTimezone(cellValue);

            if (!time) {
              break;
            }

            time.set('seconds', 0);
            time.add(1, 'minute');
            record.relLines0 = time.format('HH:mm');
            break;
          }

          case 23:
            record.relNumLi = cellValue;
            break;

          case 24: {
            record.boat.name = cellValue;
            record.boatName = cellValue;
            break;
          }

          case 25:
            record.sharkEncounter = /yes/i.test(cellValue);
            break;

          case 26: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.angler.id = email;
            }
            break;
          }

          case 27: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.captain.id = email;
            }
            break;
          }

          case 28: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.firstMate.id = email;
            }
            break;
          }

          case 29: {
            const email = this.retrieveUserEmail(cellValue);
            if (email) {
              record.secondMate.id = email;
            }
            break;
          }

          case 30:
            record.capture.notes = cellValue;
            break;

          default:
            break;
        }
      }

      const reducedFishList = record.capture.fish.reduce((acc, fish) => {
        const { name, qty } = fish || {};
        const [existingFish] = acc.filter(
          ({ name: fishName }) => fishName === name
        );

        if (existingFish) {
          existingFish.qty += qty;
          return acc;
        }

        acc.push(fish);
        return acc;
      }, []);

      record.capture.fish = reducedFishList;

      return record;
    },

    async getSystemValues(item, name) {
      const payload = {
        item,
        page: 1,
        limit: item === 'species' ? 100 : 10
      };

      if (name) {
        payload.name = name;
      }

      const response = await this.getItems(payload);
      const { docs } = response?.data || {};

      if (!docs) {
        return [];
      }

      if (Array.isArray(docs)) {
        return docs;
      }

      return Object.keys(docs).map((doc) => ({
        name: docs[doc],
        abbr: doc
      }));
    }
  }
};
</script>

<style scoped>
.bg-red {
  background-color: lightcoral;
}

.import-section {
  height: calc(100vh - 170px);
  overflow: hidden;
}

.import-wrapper {
  border: 3px dashed lightgray;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.list-container {
  height: calc(100vh - 270px);
  overflow-y: scroll;
}

.card-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  overflow-y: scroll;
  max-height: calc(100vh - 330px);
  border-top: 1px solid lightgray;
  padding-bottom: 1em;
}

.row-indicator {
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.row-confirmation {
  position: absolute;
  right: 90px;
  bottom: 10px;
}

.row-confirmation >>> button {
  border-radius: 15px;
  height: 32px !important;
}

.btn-chip {
  cursor: pointer;
}

.error-record {
  border: 1px solid red;
}

.float-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.highlight:hover {
  cursor: pointer;
  background-color: rgb(227, 242, 253);
}
</style>
